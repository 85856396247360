import React from 'react'
import Layout from '../components/layout'
// import { Link } from 'react-router-dom'

function Enterprise() {
    const backgroundStyle = {
        margin: 0,
        overflow: "hidden",
        background: `linear-gradient(rgba(1,1,1,0.7), rgba(1,1,1,0.6)), url("/enterprise.png")`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        position: "relative",
      };
    return (
        <Layout>
            <div className="min-h-screen  w-full" >
                <div className="text-center w-full relative">
                    {/* <div className="sticky top-0 z-10 px-20 flex justify-between items-center header bg-[#f8f9fb] shadow-sm">
                        <div className='w-full lg:w-auto flex justify-center'>
                            <img src="/inficonnect/logo512.png" className="h-28" />
                        </div>
                        <div className="hidden lg:flex lg:w-auto lg:order-1">
                            <ul className="flex flex-col font-medium lg:flex-row lg:space-x-8 lg:mt-0 text-lg font-normal">
                                <li>
                                    <Link
                                        className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0"
                                        to="/platform"
                                    >
                                        Platform

                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0"
                                        to="/verticals"
                                    >
                                        Verticals

                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0"
                                        to="/extensions"
                                    >
                                        Extensions

                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0"
                                        to="security"
                                    >
                                        Security

                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0"
                                        to="/implementation"
                                    >
                                        Implementation
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div> */}
                    <div
                        className="w-full flex flex-col justify-center align-middle items-center h-[50vh] xl:h-screen textShadowDropCenter"
                        style={backgroundStyle}
                    >
                    <div className=' text-white py-8'>
                        <div className='px-4 mx-auto'>
                            <h1 className='text-[28px] md:text-[44px] mb-4 md:mb-8'>Accelerate enterprise growth with customisable software solutions</h1>
                            <p className='text-sm md:text-[24px] leading-2 md:leading-8 xl:w-[80%] md:w-[70%] sm:w-[65%] w-[90%]  mx-auto mb-8 md:mb-10'>Deliver exceptional customer and employee experiences, enable seamless collaboration, automate business processes, and gather business intelligence—all with a robust software technology platform built in India.</p>
                            <button className='px-5 py-3 bg-red-500 text-white text-sm md:text-lg '>CONTACT SALES</button>
                        </div>
                    </div>

                    </div>
                    {/* <div className='w-full h-40 md:h-96 bg-[#f8f9fb] '>
                        <img src="/inficonnect/background.png" className='w-full h-full object-cover' alt="Accelerate enterprise growth with customisable software solutions" />
                    </div> */}

                    <div className="bg-[#085aac] py-8">
                        <div className="text-center pb-4 max-w-screen-xl xl:max-w-screen-2xl px-4 mx-auto">
                            <p className="text-[#a8c5e3] text-lg font-semibold mb-7">TRUSTED BY TEAMS AT</p>
                            <div className="grid justify-items-center grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 xl:gap-2 lg:gap-6 gap-8">
                                <img src="/brands/Frame 1.png" alt="TATA" className="h-16" />
                                <img src="/brands/Frame 2.png" alt="Deloitte" className="h-16" />
                                <img src="/brands/Frame 3.png" alt="Bigbasket" className="h-16" />
                                <img src="/brands/Frame 4.png" alt="Blue Star" className="h-16" />
                                <img src="/brands/Frame 5.png" alt="NPCI" className="h-16" />
                                <img src="/brands/Frame 6.png" alt="Bosch" className="h-16" />
                            </div>
                        </div>

                        {/* White Awards Container Overlapping Blue Container */}
                        <div className="xl:w-[90%] lg:w-[85%] w-full px-4 mx-auto p-8">
                            {/* <div className="flex justify-around items-start gap-8 flex-wrap">
                                <div className="flex flex-col items-center text-center max-w-xs">
                                    <div className="flex items-center gap-2 mb-2">
                                        <img src="path/to/gartner-logo.png" alt="Gartner" className="h-8" />
                                        <p className="text-blue-500 font-bold">Gartner</p>
                                    </div>
                                    <p className="text-gray-700">Fixall has been featured across multiple Gartner Magic Quadrants</p>
                                </div>
                                <div className="flex flex-col items-center text-center max-w-xs">
                                    <div className="flex items-center gap-2 mb-2">
                                        <img src="path/to/isg-logo.png" alt="ISG" className="h-8" />
                                        <p className="text-blue-500 font-bold">ISG</p>
                                    </div>
                                    <p className="text-gray-700">Fixall is a Rising Star in Provider Lens Future of Work (Workplace) Solutions, 2023</p>
                                </div>
                            </div> */}
                            <div className="bg-white rounded-lg shadow-sm p-4 xl:p-8 sm:p-6 grid grid-cols-1 sm:grid-cols-2 gap-6 text-center">
                                <div className='p-4 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] border border-transparent transition-all duration-500 ease-in-out rounded-lg hover:border-blue-500'>
                                    <h3 className="text-xl font-semibold">Gartner</h3>
                                    <p className='min-h-16 text-center m-2'>Fixall has been featured across multiple Gartner Magic Quadrants</p>
                                </div>
                                <div className='p-4 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] border border-transparent transition-all duration-500 ease-in-out rounded-lg hover:border-blue-500'>
                                    <h3 className="text-xl font-semibold">ISG</h3>
                                    <p className='min-h-16 text-center m-2'>Fixall is a Rising Star in Provider Lens Future of Work (Workplace Solutions) 2023</p>
                                </div>
                                <div className='p-4 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] border border-transparent transition-all duration-500 ease-in-out rounded-lg hover:border-blue-500'>
                                    <h3 className="text-xl font-semibold">Nucleus</h3>
                                    <p className='min-h-16 text-center m-2'>A Leader in the Talent Acquisition Technology Value Matrix 2023</p>
                                </div>
                                <div className='p-4 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] border border-transparent transition-all duration-500 ease-in-out rounded-lg hover:border-blue-500'>
                                    <h3 className="text-xl font-semibold">Dresner</h3>
                                    <p className='min-h-16 text-center m-2'>An Overall Leader in Dresner's 2023 Wisdom of Crowds Business Intelligence Market Study</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>

    )
}

export default Enterprise