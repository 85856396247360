import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineChevronRight } from "react-icons/md";
import CustomButton from "../pages/custombutton";

const HeroVideoSection = () => {
  const featuredApps = [
    {
      name: "infiConnect",
      desc: "Comprehensive CRM platform for customer-facing teams.",
      icon: "🔗",
      url: "/products/sales/inficonnect",
    },
    {
      name: "0Click",
      desc: "Secure email service for teams of all sizes.",
      icon: "📧",
      url: "/products/sales/0click",
    },
    {
      name: "Xpressbazaar",
      desc: "Powerful accounting platform for growing businesses.",
      icon: "📘",
      url: "/products/sales/xpressbazaar",
    },
    {
      name: "Qwikco",
      desc: "Organize, automate, and simplify your HR processes.",
      icon: "👥",
      url: "/products/sales/qwikco",
    },
    // {
    //   name: "Payroll",
    //   desc: "Effortless payroll processing software for businesses.",
    //   icon: "💰",
    // },
  ];
  return (
    <>
      <div className="relative h-min-screen overflow-hidden">
        {/* Video Background */}
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          autoPlay
          loop
          muted
        >
          <source src="/hero1.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Content Overlay */}
        <div className="relative flex items-center justify-center h-full bg-black bg-opacity-20">
          <div className="max-w-screen-xl xl:max-w-screen-2xl mx-auto flex flex-col px-4 lg:flex-row items-center justify-between py-20">
            {/* Left Section */}
            <div className="xl:w-3/5 md:w-1/2 text-center lg:text-left xl:pl-20 flex lg:items-start items-center justify-center flex-col">
              <h1 className="text-3xl font-semibold lg:text-5xl md:font-bold text-white leading-tight">
                Your life's work, <br />
                powered by our life's work
              </h1>
              <p className="mt-4 text-white text-lg">
                A unique and powerful software suite to transform the way you
                work. Designed for businesses of all sizes, built by a company
                that <span className="underline">values your privacy.</span>
              </p>
              <CustomButton
                to="/products"
                label="Access Your Apps"
                position="left"
                showArrow={false}
                color="#E53935"
              />
            </div>

            {/* Right Section */}
            <div className="xl:w-2/5 md:w-1/2 hidden  mt-16 lg:mt-0 lg:flex justify-center md:justify-end">
              <div className="bg-white bg-opacity-70 lg:w-1.5/3 shadow-lg rounded-md p-6">
                <h2 className="text-xl font-semibold mb-4">Featured Apps</h2>
                <ul className="space-y-4">
                  {featuredApps.map((app, index) => (
                    <Link
                      to={`${app?.url}`}
                      key={index}
                      className="flex items-center transition-all duration-200 hover:bg-zinc-100 rounded-md p-2"
                    >
                      <div className="w-10 h-10 flex items-center justify-center bg-gray-200 rounded-full text-lg">
                        {app.icon}
                      </div>
                      <div className="ml-4">
                        <h3 className="font-medium text-gray-900">
                          {app.name}
                        </h3>
                        <p className="text-sm text-gray-600">{app.desc}</p>
                      </div>
                    </Link>
                  ))}
                </ul>
                <CustomButton
                  to="/products"
                  label="Explore All Products"
                  position="left"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroVideoSection;
