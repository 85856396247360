import React from "react";
import Layout from "../components/layout";
import { Link } from "react-router-dom";

const Expressbazaar = () => {
  const backgroundStyle = {
    margin: 0,
    overflow: "hidden",
    background: `linear-gradient(rgba(1,1,1,0.7), rgba(1,1,1,0.6)), url("/bg.png")`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "relative",
  };
  return (
    <Layout>
      <div
        className="w-full flex flex-col justify-center align-middle items-center bg-[#00a69c] h-[50vh] xl:h-[80vh]"
        style={backgroundStyle}
      >
        <div className="w-full flex flex-col items-center justify-center absolute top-8">
          <div className="w-[10%] sm:w-[4%] h-[5px] rounded-3xl my-3 border-white bg-white"></div>
        </div>
        <h1 className="xl:w-1/2 text-4xl sm:text-7xl font-bold text-center uppercase text-white tracking-widest flex flex-col">
          Coming Soon
        </h1>
        <p className="xl:w-1/2 sm:w-full w-[65%] text-center sm:text-base text-sm text-white my-2">
          Stay tuned for updates – Sign up to be the first to know about what’s
          coming next.
        </p>
        <div className="w-full flex flex-col items-center justify-center">
          <div className="w-[30%] sm:w-[15%] h-[5px] rounded-3xl my-3 border-white bg-white"></div>
          <div className="w-[15%] sm:w-[7%] h-[5px] rounded-3xl my-3 border-white bg-white"></div>
        </div>
      </div>
    </Layout>
  );
};

export default Expressbazaar;
