import React from "react";
import { Link, useLocation } from "react-router-dom";
import CustomButton from "../pages/custombutton";



const Footer = () => {
  const { pathname } = useLocation();
  return (
    <div>
      {/* <section class="bg-[#A5CD39] text-white max-w-screen-xl mx-auto my-5"> */}
      {pathname !== '/request-demo' && (
        <section class="bg-[#f9edd7] text-black md:rounded-lg max-w-screen-xl xl:max-w-screen-2xl mx-auto">
        <div class=" px-4 py-8 mx-auto lg:py-16 lg:px-6">
          <div class="max-w-screen-sm mx-auto text-center">
            <h2 class="mb-4 text-2xl md:text-3xl lg:text-4xl font-bold leading-tight tracking-tight capitalize">
              Start Your Journey Today
            </h2>
            <p class="mb-6 font-normal md:text-xl">
              {/* Try Fixlall Platform for 30 days. No credit card required. */}
              Try Fixall with Complete Solution.
            </p>
            <CustomButton to="/request-demo" label="Request a Demo" color="#E53935" />

          </div>
        </div>
      </section>
      )}

      <footer class="bg-[#222E3D] ">
        <div class=" max-w-screen-xl xl:max-w-screen-2xl p-4 py-6 mx-auto lg:py-16 md:p-8 lg:p-10">
          <div class="grid text-white grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-4 mx-auto">
            <div className="">
              <h3 class="mb-6 text-sm font-medium md:font-semibold uppercase ">
                Company
              </h3>
              <ul class=" text-sm md:text-base">
                <li class="mb-4">
                  <a class="hover:underline" href="/about">
                    About
                  </a>
                </li>
                <li class="mb-4">
                  <a class="hover:underline" href="/careers">
                    Careers
                  </a>
                </li>
                <li class="mb-4">
                  <a class="hover:underline" href="/brandCenter">
                    Brand Center
                  </a>
                </li>
                <li class="mb-4">
                  <a class="hover:underline" href="/blog">
                    Blog
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 class="mb-6 text-sm font-medium md:font-semibold uppercase ">
                Help center
              </h3>
              <ul class=" text-sm md:text-base">
                <li class="mb-4">
                  <a class="hover:underline" href="#">
                    Discord Server
                  </a>
                </li>
                <li class="mb-4">
                  <a class="hover:underline" href="#">
                    Twitter
                  </a>
                </li>
                <li class="mb-4">
                  <a class="hover:underline" href="#">
                    Facebook
                  </a>
                </li>
                <li class="mb-4">
                  <a class="hover:underline" href="/contact">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 class="mb-6 text-sm font-medium md:font-semibold uppercase ">
                Legal
              </h3>
              <ul class=" text-sm md:text-base">
                <li class="mb-4">
                  <a class="hover:underline" href="/privacyPolicy">
                    Privacy Policy
                  </a>
                </li>
                <li class="mb-4">
                  <a class="hover:underline" href="/licensing">
                    Licensing
                  </a>
                </li>
                <li class="mb-4">
                  <a class="hover:underline" href="/terms">
                    Terms
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 class="mb-6 text-sm font-medium md:font-semibold uppercase ">
                Developer
              </h3>
              <ul class=" text-sm md:text-base">
                <li class="mb-4">
                  <a class="hover:underline" href="https://developer.fixall.ai">
                    Developer Center
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <hr class="my-6 border-gray-200 sm:mx-auto lg:my-8" />
          <div class="text-center">
            <ul class="flex justify-center mt-5 space-x-10">
              <li>
                <Link href="#">
                  <img src="/facebook.png" className="h-6 " />
                </Link>
              </li>
              <li>
                <Link href="#">
                  <img src="/linkedin.png" className="h-6 " />
                </Link>
              </li>
              <li>
                <Link href="#">
                  <img src="/instagram.png" className="h-6 " />
                </Link>
              </li>
              <li>
                <Link href="#">
                  <img src="/youtube.png" className="h-6 " />
                </Link>
              </li>
            </ul>
          </div>
          {/* <div class="text-center ">
            <ul class="flex justify-center mt-5 space-x-5">
              <li>
                <Link href="#">
                  <p>Home</p>
                </Link>
              </li>
              <li>
                <Link href="#">
                  <p>Contact Us</p>
                </Link>
              </li>
              <li>
                <Link href="#">
                  <p>Security</p>
                </Link>
              </li>
              <li>
                <Link href="#">
                  <p>Compliance</p>
                </Link>
              </li>
              <li>
                <Link href="#">
                  <p>IPR Complaints</p>
                </Link>
              </li>
              <li>
                <Link href="#">
                  <p> Anti-spam Policy </p>
                </Link>
              </li>
              
              
            </ul>
          </div> */}

          <div className="max-w-screen-xl mx-auto p-4 mt-4">
            <div className="flex flex-wrap justify-center gap-4 text-sm md:text-base text-white">
              <a href="#" className="hover:text-white">
                Home
              </a>
              <span className="">|</span>
              <a href="#" className="">
                Contact Us
              </a>
              <span className="">|</span>
              <a href="#" className="">
                Security
              </a>
              <span className="">|</span>
              <a href="#" className="">
                Compliance
              </a>
              <span className="">|</span>
              <a href="#" className="">
                IPR Complaints
              </a>
              <span className="">|</span>
              <a href="#" className="">
                Anti-spam Policy
              </a>
              <span className="">|</span>
              <a href="#" className="">
                Terms of Service
              </a>
              <span className="">|</span>
              <a href="#" className="">
                Privacy Policy
              </a>
              <span className="">|</span>
              <a href="#" className="">
                Cookie Policy
              </a>
              <span className="">|</span>
              <a href="#" className="">
                GDPR Compliance
              </a>
              <span className="">|</span>
              <a href="#" className="">
                Abuse Policy
              </a>
            </div>
          </div>
        </div>

        {/* Bottom Footer */}
        <div className="text-center p-10 bg-[#101215]">
          <Link
            class="flex items-center justify-center mb-5 text-2xl font-semibold text-gray-900 "
            to="/"
          >
            <img src="/fixlabWhiteLogo.png" alt="" className="h-12" />
          </Link>

          <span class="block text-sm text-center text-white">
            © 2021-2022 Fixall™. All Rights Reserved. Built with
          </span>
        </div>
      </footer>
    </div>
  );
};

export default Footer;