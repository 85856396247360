import React from 'react';
import { MdOutlineChevronRight } from 'react-icons/md';
import { Link } from 'react-router-dom';

const CustomButton = ({
    to = "/",
    label = "Explore All Products",
    color = "#7453E0",
    textColor = "white",
    showArrow = true,
    className = "",
    position,
    marginTop
}) => {
    return (
        <div className={`flex items-center mt-8`} style={{ justifyContent: position ? position : "center", marginTop: marginTop ? marginTop : "2rem" }}>
            <Link to={to}>
                <button
                    className={`flex items-center justify-center gap-2 px-4 py-2 rounded-sm font-semibold text-sm md:text-base ${className}`}
                    style={{ backgroundColor: color, color: textColor }}
                >
                    {label}
                    {showArrow && <MdOutlineChevronRight className="slideRight mt-1" fontSize="large" />}
                </button>
            </Link>
        </div>
    );
};

export default CustomButton;
